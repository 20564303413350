<template>
  <!-- Mobile version -->
  <div v-if="isMobileDevice">
    <FracPackImage
      :glowColor="fracpack.color_glow_hex"
      :image="fracpack.image"
    />

    <FracPackDescription
      class="mt-n-6xl"
      :name="fracpack.name"
      :cardsPerPack="fracpack.cards_per_pack"
      :price="price"
      :supplyTotal="fracpack.initial_supply"
      :supplyRemaining="fracpack.remaining_supply"
    />

    <FracPackPurchase
      class="mt-n-4xl"
      :showingReviewOrder="showingReviewOrder"
      :name="fracpack.name"
      :price="price"
      :balance="currentBalance"
      :isLoading="isLoading"
      :hasSupply="hasSupply"
      @showReviewOrder="showReviewOrder"
      @buyPack="buyPack"
    />

    <FracPackStats
      class="mt-n-4xl"
      :cardsPerPack="fracpack.cards_per_pack"
      :fractionPerCard="fractionPerCard"
      :possibleAssets="fracpack.possible_assets"
    />
  </div>

  <!-- Desktop version -->
  <div v-else class="flex justify-center mt-n-xl">
    <!-- Left side -->
    <div class="left-side-desktop-container">
      <FracPackDescription
        :name="fracpack.name"
        :cardsPerPack="fracpack.cards_per_pack"
        :price="price"
        :supplyTotal="fracpack.initial_supply"
        :supplyRemaining="fracpack.remaining_supply"
      />

      <FracPackStats
        class="mt-n-4xl"
        :cardsPerPack="fracpack.cards_per_pack"
        :fractionPerCard="fractionPerCard"
        :possibleAssets="fracpack.possible_assets"
      />
    </div>

    <!-- Right side -->
    <div class="right-side-desktop-container">
      <FracPackImage
        :glowColor="fracpack.color_glow_hex"
        :image="fracpack.image"
      />

      <FracPackPurchase
        class="mt-n-4xl"
        :showingReviewOrder="showingReviewOrder"
        :name="fracpack.name"
        :price="price"
        :balance="currentBalance"
        :isLoading="isLoading"
        :hasSupply="hasSupply"
        @showReviewOrder="showReviewOrder"
        @buyPack="buyPack"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  FracPackDescription,
  FracPackImage,
  FracPackPurchase,
  FracPackStats,
} from '@/components/fracpack';
 
import FracPackBuy from '@/graphql/mutations/FracPackBuy.gql';

import { mountPackList } from '@/modules/asset/utils';

export default {
  name: 'FracPack',
  components: {
    FracPackDescription,
    FracPackImage,
    FracPackPurchase,
    FracPackStats,
  },

  data() {
    return {
      showingReviewOrder: false,
    };
  },
  
  computed: {
    ...mapState('fracpack', [
      'isLoading',
    ]),

    ...mapGetters('fracpack', [
      'fracpackTrackingData',
    ]),

    fracpack() {
      return this.lodashGet(this.$store.state.api, 'fracpack', {});
    },

    hasSupply() {
      return this.fracpack.remaining_supply > 0;
    },

    currentBalance() {
      try {
        return this.$big(
            this.lodashGet(this.$store.state.api, 'current_user.usd_balance.amount', 0)
        ).toNumber();
      } catch (err) {
        return 0;
      }
    },

    fractionPerCard() {
      try {
        return this.$big(this.fracpack.fraction_per_card).toNumber();
      } catch (err) {
        return 0;
      }
    },

    price() {
      try {
        return this.$big(this.fracpack.price).toNumber();
      } catch (err) {
        return 0;
      }
    },
  },
  
  methods: {
    ...mapActions('user', ['requiresKycComplete']),

    async buyPack() {
      try {
        const request = await this.apolloApiCall({
          $apollo: this.$apollo,
          mutation: FracPackBuy,
          variables: {
            fracpack_definition_id: this.fracpack.id,
            pack_amount: 1,
          }
        });

        const fracpack = this.lodashGet(request.data, 'fracpack_buy_fracpack.fracpacks', null);

        const mountedFracpack = mountPackList(fracpack);

        this.showModal('FracPack', {
          pack: {
            ...mountedFracpack[0].rawValues,
            variation: 'fracpack',
          },
          noPadding: true,
        });
      } catch (error) {
        await this.showError(error);
      } finally {
        this.showingReviewOrder = false;
      }
    },

    async preBuyChecks() {
      try {
        await this.$store.dispatch('fracpack/setLoading', true);

        await this.requiresKycComplete();
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);
      } finally {
        await this.$store.dispatch('fracpack/setLoading', false);
      }
    },

    async showReviewOrder() {
      await this.preBuyChecks();
      this.showingReviewOrder = true;
    },

    async loaded() {
      await this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_DETAIL_VIEWED',
        variables: {
          ...this.fracpackTrackingData,
        },
      });
    }
  },

  async mounted() {
    if (!this.globalIsLoading) {
      await this.loaded();
    }

    // Resetting page state
    await this.$store.dispatch('fracpack/setLoading', false);
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>

<style scoped>
  .left-side-desktop-container {
    width: 450px;
  }

  .right-side-desktop-container {
    margin-left: 120px;
    width: 200px;
  }
</style>
