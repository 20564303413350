
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FracPack"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FracPack"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"time_bought"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buy_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"definition"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FracpackDefinition"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cards_inside"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FracPackCard"},"directives":[]}]}}]}}],"loc":{"start":0,"end":260}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/FracPackCard.gql\"\n#import \"@/graphql/fragments/FracpackDefinition.gql\"\n\nfragment FracPack on FracPack {\n  id\n  state\n  time_bought\n  buy_price\n  definition {\n    ...FracpackDefinition\n  }\n  cards_inside {\n    ...FracPackCard\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FracPackCard.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FracpackDefinition.gql").definitions));


      module.exports = doc;
    
