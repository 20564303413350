
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FracPackBuy"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FracPackBuy"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"definition"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FracpackDefinition"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fracpacks"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FracPack"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":225}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/FracPack.gql\"\n#import \"@/graphql/fragments/FracpackDefinition.gql\"\n\nfragment FracPackBuy on FracPackBuy {\n  definition {\n    ...FracpackDefinition\n  }\n  fracpacks {\n    ...FracPack\n  }\n  amount\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FracPack.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FracpackDefinition.gql").definitions));


      module.exports = doc;
    
